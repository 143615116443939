/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Circle, Heading, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { FaQuestion, FaPills } from 'react-icons/fa';
import { BiPackage } from 'react-icons/bi';

const FeaturesCard = () => {
  const cards = [
    {
      icon: FaQuestion,
      title: 'Take the quiz',
      description: 'Answer a few questions about your health and lifestyle.'
    },
    {
      icon: FaPills,
      title: 'Personalised profile',
      description: 'A personalised vitamin subscription is created for you based on your profile.'
    },
    {
      icon: BiPackage,
      title: 'Convenient delivery',
      description: 'Your personalised vitamin box is delivered to your door monthly.'
    }
  ];

  return (
    <Box as='section' id='how-it-works' maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }} my={12}>
      <Heading as='h2' size={{ base: 'md', md: 'lg' }} mb={10}>
        Simplified health
      </Heading>
      <SimpleGrid spacing={8} columns={[1, 1, 1, 3]}>
        {cards.map((card, idx) => (
          <Box mb='4' key={idx}>
            <Circle
              bgGradient='linear(to-br, orange.500,pink.400)'
              className='icon-box rounded-circle text-contrast shadow icon-xl'
            >
              <Icon as={card.icon} fill='gray.50' boxSize='9' />
            </Circle>
            <Heading as='h4' mt={8} fontSize='xl'>
              {card.title}
            </Heading>

            <Box w='25%' borderWidth={2} mt={3} mb={4} borderColor='orange.400' />
            <Text color='gray.500'>{card.description}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default FeaturesCard;
